import { ygritteContext } from 'ygritte/context.js';
import { log } from 'ygritte/logging.js';
import { insertPixels } from 'ygritte/pixels.js';
import { tryJsonFetch } from 'ygritte/util/fetch.js';

/*

Makes a call to the /api2/sync endpoint to initialize our tracking.

*/

const path = '/api2/sync';

export class InitializeCommand {
    async execute(): Promise<void> {
        log(`calling ${path}`);

        const url = new URL(`${ygritteContext.origin}${path}`);
        url.searchParams.append('token', ygritteContext.token);
        url.searchParams.append('pageUrl', ygritteContext.pageUrl);

        const fetchResult = await tryJsonFetch(url);

        if (!fetchResult.success)
            throw new Error(
                `Failed to fetch ${path}: ${JSON.stringify(fetchResult.error)}`,
            );

        log(`${path} returned`);

        await insertPixels(fetchResult);
    }
}
