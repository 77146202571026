import { getErrorMessage } from '@libraries/get-error-message';

export class FetchError extends Error {
    constructor(url: URL, err: string) {
        const message = `Failed to fetch URL ${url.toString()}: ${err}`;
        super(message);
    }
}

export type FetchResultError = { success: false; error: FetchError };
export type FetchResultSuccess = { success: true; response: Response };
export type FetchResult = FetchResultError | FetchResultSuccess;

export type JsonFetchResultSuccess = FetchResultSuccess & { data: unknown };
export type JsonFetchResult = FetchResultError | JsonFetchResultSuccess;

export const tryFetch = async (
    url: URL,
    init?: RequestInit,
): Promise<FetchResult> => {
    let response;

    try {
        response = await fetch(url, init);
    } catch (e) {
        return {
            success: false,
            error: new FetchError(url, getErrorMessage(e)),
        };
    }

    if (!response.ok) {
        return {
            success: false,
            error: new FetchError(url, `Received status ${response.status}`),
        };
    }

    return {
        success: true,
        response,
    };
};

export const tryJsonFetch = async (
    url: URL,
    init?: RequestInit,
): Promise<JsonFetchResult> => {
    const fetchResult = await tryFetch(url, init);
    if (!fetchResult.success) return fetchResult;

    try {
        const data = await fetchResult.response.json();
        return { success: true, response: fetchResult.response, data };
    } catch (e) {
        const msg = getErrorMessage(e);
        return {
            success: false,
            error: new FetchError(url, `Failed to get JSON response: ${msg}`),
        };
    }
};

export const tryJsonGet = async (params: {
    origin: string;
    path: string;
    data: Record<string, string>;
}): Promise<JsonFetchResult> => {
    const url = new URL(`${params.origin}${params.path}`);

    if (Object.keys(params.data).length > 0) {
        url.searchParams.set('json', JSON.stringify(params.data));
    }

    const fetchResult = await tryJsonFetch(url);
    return fetchResult;
};

export const tryJsonPost = async (params: {
    origin: string;
    path: string;
    data: unknown;
}): Promise<JsonFetchResult> => {
    const url = new URL(`${params.origin}${params.path}`);

    const fetchResult = await tryJsonFetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params.data),
    });

    return fetchResult;
};
