import { commandRunner } from 'ygritte/commands/runner.js';
import { error } from 'ygritte/logging.js';
import { initElementScanner } from 'ygritte/setup/element-scanner.js';
import { processQueue } from 'ygritte/setup/queue.js';

const runYgritte = async () => {
    try {
        await commandRunner.init();
        await processQueue();
        await initElementScanner();
    } catch (err) {
        error(`Trapped error: ${JSON.stringify(err)}`);
    }
};

// Use void to explicitly ignore the promise
void runYgritte();
