import { ygritteContext } from 'ygritte/context.js';
import { error, log } from 'ygritte/logging.js';
import { parseGoal } from 'ygritte/parsers/goal.js';
import { insertPixels } from 'ygritte/pixels.js';
import type { JsonFetchResult } from 'ygritte/util/fetch.js';
import { tryJsonPost } from 'ygritte/util/fetch.js';
import type { Goal } from '~/domain/goal.js';

/*

Makes a call to the /api2/goal endpoint if given valid goal data.

*/

const apiPath = '/api2/goal';

export class SendGoalCommand {
    constructor(public args: unknown) {}

    private getGoal(): Goal | undefined {
        const goal = parseGoal(this.args);

        if (!goal) {
            error(`Invalid goal: ${JSON.stringify(this.args)}`);
            return undefined;
        }

        return goal;
    }

    private async callApi(goal: Goal) {
        log(`calling ${apiPath}`);

        const fetchResult = await tryJsonPost({
            origin: ygritteContext.origin,
            path: apiPath,
            data: {
                token: ygritteContext.token,
                goal: goal,
            },
        });

        return fetchResult;
    }

    private async handleResponse(fetchResult: JsonFetchResult) {
        if (!fetchResult.success) {
            error(
                `Error in call to ${apiPath}: ${JSON.stringify(fetchResult.error)}`,
            );
            return;
        }

        log(`Successfully called ${apiPath}`);

        await insertPixels(fetchResult);
    }

    async execute(): Promise<void> {
        const goal = this.getGoal();
        if (!goal) return;
        const fetchResult = await this.callApi(goal);
        await this.handleResponse(fetchResult);
    }
}
