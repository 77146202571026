import { ygritteContext } from './context';

class Logger {
    private debugEnabled: boolean;

    constructor() {
        this.debugEnabled = this.checkDebugEnabled();
    }

    private checkDebugEnabled(): boolean {
        const hash = window.location.hash;
        return hash.includes('ygritte/debug');
    }

    log(message: string): void {
        if (!this.debugEnabled) return;
        console.log(message);
    }

    warn(message: string): void {
        if (!this.debugEnabled) return;
        console.warn(message);
    }

    error(message: string): void {
        if (this.debugEnabled) {
            console.error(message);
        }
        this.logErrorToServer(message);
    }

    logErrorToServer(
        message: string,
        extra: Record<string, unknown> = {},
    ): void {
        const errorData = {
            message,
            token: ygritteContext.token,
            pageUrl: ygritteContext.pageUrl,
            ...extra,
        };

        // Use fetch to send error data to our server
        // Use void to explicitly ignore the promise
        void fetch(`${ygritteContext.origin}/api2/log-error`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(errorData),
        });
    }
}

const logger = new Logger();

export const error = logger.error.bind(logger);
export const warn = logger.warn.bind(logger);
export const log = logger.log.bind(logger);
