import { log } from 'ygritte/logging.js';
import type { JsonFetchResultSuccess } from 'ygritte/util/fetch.js';

const getPixels = (fetchResult: JsonFetchResultSuccess) => {
    if (!fetchResult.data) return [];
    if (typeof fetchResult.data !== 'object') return [];
    if (!('pixels' in fetchResult.data)) return [];

    const pixelsRaw = fetchResult.data.pixels;
    if (!Array.isArray(pixelsRaw)) return [];

    const pixels = pixelsRaw.filter(
        (pixel): pixel is string => typeof pixel === 'string',
    );

    return pixels;
};

const insertPixel = async (pixel: string): Promise<void> => {
    try {
        await fetch(pixel, { mode: 'no-cors' });
        log(`Pixel request completed successfully: ${pixel}`);
    } catch (err) {
        log(`Pixel request errored: ${pixel}: ${JSON.stringify(err)}`);
    }
};

export const insertPixels = async (
    fetchResult: JsonFetchResultSuccess,
): Promise<void> => {
    const pixels = getPixels(fetchResult);

    for (const pixel of pixels) {
        await Promise.all([
            insertPixel(pixel),
            new Promise((resolve) => setTimeout(resolve, 25)),
        ]);
    }
};
