class YgritteContext {
    private scriptUrl: URL;

    constructor() {
        this.scriptUrl = this.getScriptUrl();
    }

    // Get the URL of the current script
    private getScriptUrl(): URL {
        if (!document.currentScript) throw new Error('No current script found');
        if (!('src' in document.currentScript))
            throw new Error('No src found on current script');
        const scriptUrl = new URL(document.currentScript.src);
        return scriptUrl;
    }

    // Get the publisher's token from the script path
    get token(): string {
        // Make sure the path matches /j/<hash>/ygritte.js or ygritte2.js
        const match = /^\/j\/([^/]+)\/ygritte2?\.js$/.exec(
            this.scriptUrl.pathname,
        );
        if (!match) throw new Error('Invalid script path');

        // Extract the token from the path
        const token = match[1];
        if (!token) throw new Error('No token found in script path');

        return token;
    }

    // Get the origin of the script URL, we will use this to make API requests
    get origin(): string {
        return this.scriptUrl.origin;
    }

    // Get the URL of the page hosting the script
    get pageUrl(): string {
        return window.location.href;
    }
}

export const ygritteContext = new YgritteContext();
