import { runCommand } from 'ygritte/commands/run-command.js';
import { isIterable } from 'ygritte/util/iterable.js';

/*

Our parent page might have a window.ygritte object that holds an internal queue, if the publisher is following our instructions:

<script>window.ygritte=window.ygritte||function(){(ygritte.q=ygritte.q||[]).push(arguments)};ygritte.l=+new Date;</script>
<script async id="ygritte-js" src="https://s.edkay.com/j/${TOKEN}/ygritte.js"></script>

Once our script is loaded, we want to replace window.ygritte to route to our runCommand() function. Any items in the queue need to be run first, however.

*/

const getQueue = (): Array<unknown> => {
    if (
        'ygritte' in window &&
        typeof window.ygritte === 'function' &&
        'q' in window.ygritte &&
        Array.isArray(window.ygritte.q)
    )
        return window.ygritte.q;
    return [];
};

const runQueue = async (queue: Array<unknown>): Promise<void> => {
    while (queue.length > 0) {
        const command = queue.shift();
        if (!isIterable(command)) continue;
        await runCommand(...command);
    }
};

export const processQueue = async (): Promise<void> => {
    // Run any commands in the queue
    const queue = getQueue();
    await runQueue(queue);

    // Now map window.ygritte to our runCommand function
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (window as any).ygritte = runCommand;
};
