import { runCommand } from 'ygritte/commands/run-command.js';
import { error, log } from 'ygritte/logging.js';

const isProcessed = (element: Element): boolean =>
    element.hasAttribute('data-ygritte-processed');

const setProcessed = (element: Element) => {
    element.setAttribute('data-ygritte-processed', '');
};

const getGoalElements = () =>
    Array.from(document.querySelectorAll('[data-ygritte-goal]'));

const processGoalElement = async (element: Element) => {
    const attribute = element.getAttribute('data-ygritte-goal');
    if (!attribute) return;

    let args;
    try {
        args = JSON.parse(attribute);
    } catch {
        error(`Failed to parse JSON for goal: ${attribute}`);
        return;
    }

    await runCommand('goal', args);
    setProcessed(element);
};

const processGoalElements = async () => {
    const elements = getGoalElements();
    const unprocessed = elements.filter((element) => !isProcessed(element));
    for (const element of unprocessed) {
        await processGoalElement(element);
    }
};

const getVisitorUpdateElements = () =>
    Array.from(document.querySelectorAll('[data-ygritte-props]'));

const processVisitorUpdateElement = async (element: Element) => {
    const attribute = element.getAttribute('data-ygritte-props');
    if (!attribute) return;

    let args;
    try {
        args = JSON.parse(attribute);
    } catch {
        error(`Failed to parse JSON for props: ${attribute}`);
        return;
    }

    await runCommand('visitorUpdate', args);
    setProcessed(element);
};

const processVisitorUpdates = async () => {
    const elements = getVisitorUpdateElements();
    const unprocessed = elements.filter((element) => !isProcessed(element));
    for (const element of unprocessed) {
        await processVisitorUpdateElement(element);
    }
};

const processElements = async () => {
    try {
        await processVisitorUpdates();
        await processGoalElements();
    } catch (err) {
        error(`Error processing elements: ${String(err)}`);
    }
};

export const initElementScanner = async (): Promise<void> => {
    log('Initializing element scanner');

    if (document.readyState === 'complete') {
        await processElements();
    }

    document.addEventListener('DOMContentLoaded', () => {
        void processElements();
        setTimeout(() => void processElements(), 1000);
    });

    window.addEventListener('load', () => {
        void processElements();
        setTimeout(() => void processElements(), 1000);
        setTimeout(() => void processElements(), 5000);
    });
};
